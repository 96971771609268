import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // baseUrl: 'http://192.168.1.7:8081',
    baseUrl: 'http://162.254.37.151:8081',

    userData: JSON.parse(localStorage.getItem('userData')),

    ability : JSON.parse(localStorage.getItem('ability')),

    token: localStorage.getItem('token'),
    userId: localStorage.getItem('userId'),
    userName: localStorage.getItem('userName'),
    name: localStorage.getItem('name'),
    photo: localStorage.getItem('photo'),
    isEnebled:localStorage.getItem('isEnebled'),
    roles: JSON.parse(localStorage.getItem('roles')),
    birthday:localStorage.getItem('birthday'),
    anniversary:localStorage.getItem('anniversary'),
    empId: localStorage.getItem('empId'),
    storeId: localStorage.getItem('storeId'),
    storeName: localStorage.getItem('storeName'),
    maxDiscountRestricationFlat: localStorage.getItem('maxDiscountRestricationFlat'),
    maxDiscountRestricationPersentage: localStorage.getItem('maxDiscountRestricationPersentage'),
    forcePasswordChange: localStorage.getItem('forcePasswordChange'),
    
    defaultConfiguration: JSON.parse(localStorage.getItem('defaultConfiguration')),
  },
  mutations:{
    UPDATE_TOKEN(ability,userData,state,token,userId, userName,name,photo,isEnebled,birthday,anniversary,storeId,storeName,empId,forcePasswordChange,roles,defaultConfiguration){
      state.ability=ability
      state.userData=userData
      state.token = token
      state.userId=userId
      state.userName=userName
      state.name=name
      state.photo=photo
      state.isEnebled=isEnebled
      state.roles=roles
      state.birthday=birthday
      state.anniversary=anniversary
      state.storeId=storeId
      state.storeName=storeName
      state.empId=empId
      state.forcePasswordChange=forcePasswordChange
      state.defaultConfiguration=defaultConfiguration
    },
    
    
  },

  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
