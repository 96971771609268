export default [
    //Reports
    {
        path: '/report/orders',
        name: 'orders',
        component: () => import('@/views/report/Orders.vue'),
    },
    {
        path: '/report/allCustomerList',
        name: 'allCustomerList',
        component: () => import('@/views/report/AllCustomersList.vue'),
    },
    {
        path: '/report/sales-delivery',
        name: 'salesDelivery',
        component: () => import('@/views/report/SalesAndDelivery.vue'),
    },
    {
        path: '/report/service-wise-order',
        name: 'serviceWiseOrder',
        component: () => import('@/views/report/ServiceWiseOrder.vue'),
    },
    {
        path: '/report/service-and-garment',
        name: 'serviceAndGarment',
        component: () => import('@/views/report/ServiceAndGarment.vue'),
    },
    {
        path: '/report/categoryWiseReport',
        name: 'categoryWiseReport',
        component: () => import('@/views/report/CategoryWiseReport.vue'),
    },
    {
        path: '/report/garment-wise',
        name: 'garmentWise',
        component: () => import('@/views/report/GarmentWise.vue'),
    },
    {
        path: '/report/garment-status',
        name: 'garmentStatus',
        component: () => import('@/views/report/GarmentStatus.vue'),
    },
    {
        path: '/report/garment-details',
        name: 'garmentDetails',
        component: () => import('@/views/report/GarmentDetails.vue'),
    },
    {
        path: '/report/payment-adjustment',
        name: 'paymentAdjustment',
        component: () => import('@/views/report/PaymentAdjustment.vue'),
    },
    {
        path: '/report/daily-customer-addition',
        name: 'dailyCustomerAddition',
        component: () => import('@/views/report/DailyCustomerAddition.vue'),
    },
    {
        path: '/report/marked-ready-by-user',
        name: 'markedReadyByUser',
        component: () => import('@/views/report/MarkedReadyByUser.vue'),
    },
    {
        path: '/report/without-ticket-delivery',
        name: 'withoutTicketDelivery',
        component: () => import('@/views/report/WithoutTicketDelivery.vue'),
    },
    {
        path: '/report/order-by-locality',
        name: 'orderByLocality',
        component: () => import('@/views/report/OrderByLocality.vue'),
    },
    {
        path: '/report/pending-stock',
        name: 'pendingStock',
        component: () => import('@/views/report/PendingStock.vue'),
    },
]