export default [
    //Order
    {
        path: '/order/order-receipt',
        name: 'autoOrderReceipt',
        component: () => import('@/views/order/AutoOrderReceipt.vue'),
    },
    {
        path: '/order/cancel-order',
        name: 'cancelOrder',
        component: () => import('@/views/order/CancelOrder.vue'),
    },
    {
        path: '/order/delete-order',
        name: 'deleteOrder',
        component: () => import('@/views/order/DeleteOrder.vue'),
    },
    {
        path: '/order/new-order',
        name: 'newOrder',
        component: () => import('@/views/order/NewOrder.vue'),
    },
    {
        path: '/order/order-detail',
        name: 'orderDetail',
        component: () => import('@/views/order/OrderDetail.vue'),
    },
    {
        path: '/order/attributes',
        name: 'attributes',
        component: () => import('@/views/order/Attributes.vue'),
    },
]