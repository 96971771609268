export default [
    {
        path: '/wallet/currency-type',
        name: 'currencyType',
        component: () => import('@/views/wallet/CurrencyType.vue'),
    },
    {
        path: '/wallet/wallet-transaction-type',
        name: 'walletTransactionType',
        component: () => import('@/views/wallet/WalletTransactionType.vue'),
    },
    {
        path: '/wallet/wallet-list',
        name: 'walletList',
        component: () => import('@/views/wallet/WalletList.vue'),
    },
    {
        path: '/wallet/transaction-detail',
        name: 'transactionDetail',
        component: () => import('@/views/wallet/TransactionDetail.vue'),
    },
    {
        path: '/wallet/create-transaction',
        name: 'createTransaction',
        component: () => import('@/views/wallet/CreateTransaction.vue'),
    },
]