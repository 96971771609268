export default [
    //Process
    {
        path: '/process/print-tag',
        name: 'printTag',
        component: () => import('@/views/process/PrintQRTags.vue'),
    },
    {
        path: '/process/send-workshop',
        name: 'sendWorkshop',
        component: () => import('@/views/process/SendToWorkshop.vue'),
    },
    {
        path: '/process/receive-workshop',
        name: 'receiveWorkshop',
        component: () => import('@/views/process/ReceiveFromWorkshop.vue'),
    },
    {
        path: '/process/pending-finishing',
        name: 'pendingFinishing',
        component: () => import('@/views/process/PendingForFinishing.vue'),
    },
    {
        path: '/process/packing-sticker',
        name: 'packingSticker',
        component: () => import('@/views/process/PackingStickers.vue'),
    },
    {
        path: '/process/return-to-workshop',
        name: 'returnToWorkShop',
        component: () => import('@/views/process/OrderItemReturnToWorkshopGarmentList.vue'),
    },
    {
        path: '/process/return-to-store',
        name: 'returnToStore',
        component: () => import('@/views/process/OrderItemReturnToStoreGarmentList.vue'),
    },
]