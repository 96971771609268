export default [
    //Admin
    {
        path: '/admin/change-password',
        name: 'changePassword',
        component: () => import('@/views/admin/ChangePassword.vue'),
    },
    {
        path: '/admin/access-authentication',
        name: 'accessAuthentication',
        component: () => import('@/views/admin/AccessAuthentication.vue'),
    },
    {
        path: '/admin/setup/invoice-labels',
        name: 'invoiceLabel',
        component: () => import('@/views/admin/setup/InvoiceLabels.vue'),
    },
    {
        path: '/admin/setup',
        name: 'setup',
        component: () => import('@/views/admin/setup/Setup.vue'),
    },
    {
        path: '/admin/design-tag',
        name: 'designTag',
        component: () => import('@/views/admin/DesignTag.vue'),
    },
    {
        path: '/admin/search-invoice',
        name: 'searchInvoice',
        component: () => import('@/views/admin/SearchInvoice.vue'),
    },
    {
        path: '/admin/sms-configuration',
        name: 'smsConfiguration',
        component: () => import('@/views/admin/SMSConfiguration.vue'),
    },
    {
        path: '/admin/discount-restriction',
        name: 'discountRestriction',
        component: () => import('@/views/admin/DiscountRestriction.vue'),
    },
    {
        path: '/admin/sms-template-edit',
        name: 'smstemplateEdit',
        component: () => import('@/views/admin/SMSTemplateEdit.vue'),
    },
    {
        path: '/admin/daily-dashboard',
        name: 'dailyDashboard',
        component: () => import('@/views/admin/DailyDashboard.vue'),
    },
    {
        path: '/admin/payment-gateway-setup',
        name: 'paymentGatewaySetup',
        component: () => import('@/views/admin/PaymentGatewaySetup.vue'),
    },
    {
        path: '/admin/email-configuration',
        name: 'emailConfiguration',
        component: () => import('@/views/admin/EmailConfiguration.vue'),
    },
    {
        path: '/admin/sticker-configuration',
        name: 'stickerConfiguration',
        component: () => import('@/views/admin/StickerConfiguration.vue'),
    },
]