export default [
  //Customer
  {
    path: '/customer/home',
    name: 'home',
    component: ()=> import('@/views/customer/Home.vue'),
  },
  
  {
    path: '/customer/add-new',
    name: 'addNew',
    component: ()=> import('@/views/customer/AddNew.vue'),
  },
  {
    path: '/customer/customer-list',
    name: 'customerList',
    component: ()=> import('@/views/customer/CustomerList.vue'),
  },
  {
    path: '/customer/edit-customer',
    name: 'editCustomer',
    component: ()=> import('@/views/customer/EditCustomer.vue'),
  },
  {
    path: '/customer/customer-detail',
    name: 'customerDetail',
    component: ()=> import('@/views/customer/CustomerDetails.vue'),
  },
  {
    path: '/customer/merge',
    name: 'merge',
    component: ()=> import('@/views/customer/Merge.vue'),
  },
  {
    path: '/customer/send-sms',
    name: 'sendSms',
    component: ()=> import('@/views/customer/SendPromotionalSMS.vue'),
  },
]
