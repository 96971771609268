export default [
    //Account
    {
        path: '/account/account-group',
        name: 'accountsGroup',
        component: () => import('@/views/account/AccountsGroup.vue'),
    },
    {
        path: '/account/account',
        name: 'account',
        component: () => import('@/views/account/Account.vue'),
    },
    {
        path: '/account/vendor',
        name: 'vendor',
        component: () => import('@/views/account/Vendor.vue'),
    },
    {
        path: '/account/vendor-transaction-detail',
        name: 'vendorTransactionDetail',
        component: () => import('@/views/account/VendorTransactionDetail.vue'),
    },
    {
        path: '/account/addVendor',
        name: 'addVendor',
        component: () => import('@/views/account/Vendor.vue'),
    },
    {
        path: '/account/cash-day-book',
        name: 'cashDayBook',
        component: () => import('@/views/account/CashDayBook.vue'),
    },
    {
        path: '/account/detail-cash-day-book',
        name: 'detailCashDayBook',
        component: () => import('@/views/account/DetailCashDayBook.vue'),
    },
    {
        path: '/account/account-receivable',
        name: 'accountReceivable',
        component: () => import('@/views/account/AccountsReceivable.vue'),
    },
    {
        path: '/account/payment',
        name: 'payment',
        component: () => import('@/views/account/Payment.vue'),
    },
    {
        path: '/account/receipt',
        name: 'receipt',
        component: () => import('@/views/account/Receipt.vue'),
    },
    {
        path: '/account/payment-type',
        name: 'paymentType',
        component: () => import('@/views/account/PaymentType.vue'),
    },
    {
        path: '/account/tax-report',
        name: 'taxReport',
        component: () => import('@/views/account/TaxReport.vue'),
    },
]