export default [
  //MasterData
{
  path: '/master-data/store-information',
  name: 'storeInformation',
  component: ()=> import('@/views/master-data/StoreInformation.vue'),
},
{
  path: '/master-data/workshop-information',
  name: 'workshopInformation',
  component: ()=> import('@/views/master-data/WorkshopInformation.vue'),
},
{
  path: '/master-data/price-list',
  name: 'priceList',
  component: ()=> import('@/views/master-data/PriceList.vue'),
},
{
  path: '/master-data/item-category',
  name: 'itemCategory',
  component: ()=> import('@/views/master-data/ItemCategory.vue'),
},
{
  path: '/master-data/services',
  name: 'services',
  component: ()=> import('@/views/master-data/Services.vue'),
},
{
  path: '/master-data/items',
  name: 'items',
  component: ()=> import('@/views/master-data/Items.vue'),
},
{
  path: '/master-data/garment-remarks',
  name: 'garmentRemarks',
  component: ()=> import('@/views/master-data/GarmentRemarks.vue'),
},
{
  path: '/master-data/topup-services',
  name: 'topupServices',
  component: ()=> import('@/views/master-data/TopupServices.vue'),
},
{
  path: '/master-data/brand',
  name: 'brand',
  component: ()=> import('@/views/master-data/Brand.vue'),
},
{
  path: '/master-data/colour',
  name: 'colour',
  component: ()=> import('@/views/master-data/Colour.vue'),
},
{
  path: '/master-data/garment-return-cause',
  name: 'garmentReturnCause',
  component: ()=> import('@/views/master-data/GarmentReturnCause.vue'),
},
{
  path: '/master-data/payment-adjustment-type',
  name: 'paymentAdjustmentType',
  component: ()=> import('@/views/master-data/PaymentAdjustmentType.vue'),
},
{
  path: '/master-data/payment-mode-type',
  name: 'paymentModeType',
  component: ()=> import('@/views/master-data/PaymentModeTypes.vue'),
},
{
  path: '/master-data/holidays',
  name: 'holidays',
  component: ()=> import('@/views/master-data/Holidays.vue'),
},
{
  path: '/master-data/customer-preference',
  name: 'customerPreference',
  component: ()=> import('@/views/master-data/CustomerPerferences.vue'),
},
{
  path: '/master-data/communication-preference',
  name: 'communicationPreference',
  component: ()=> import('@/views/master-data/CommunicationPerferences.vue'),
},
{
  path: '/master-data/employee-list',
  name: 'employeeList',
  component: ()=> import('@/views/master-data/Employee/EmployeeList.vue'),
},
{
  path: '/master-data/addEmployee',
  name: 'addEmployee',
  component: ()=> import('@/views/master-data/Employee/AddEmployee.vue'),
},
{
  path: '/master-data/editEmployee',
  name: 'editEmployee',
  component: ()=> import('@/views/master-data/Employee/EditEmployee.vue'),
},
]