export default [
    //My Account
    {
        path: '/master-data/my-account',
        name: 'myAccount',
        component: () => import('@/views/my-account/MyAccount.vue'),
    },

    {
        path: '/user/changePassword',
        name: 'profileChangePassword',
        component:()=> import('@/views/my-account/ProfileChangePassword.vue'),
        meta: {
            resource: 'ACL',
            action: 'read',
          },
    }
]