import store from '@/store/index'
import moment from 'moment'
export default {

    // Start convert Date
    convertTimeStampToDate(value) {
        if (value) {
            return moment.utc(value).format('DD/MM/YYYY hh:mm A');
        } else {
            return '';
        }
    },

    convertTimeStampToDateLocal(value) {
        if (value) {
            return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
        } else {
            return '';
        }
    },
    // End convert Date

    // Start convert Date
    convertTimeStampToDateWithoutTime(value) {
        if (value) {
            return moment.utc(value).format('DD/MM/YYYY');
        } else {
            return '';
        }
    },
    // End convert Date

    // Start Home Delivery
    homeDeliveryOptions() {
        var homeDeliveryOptions = ['Yes', 'No'];
        return homeDeliveryOptions;
    },
    homeDelivery() {
        var homeDelivery = '';
        return homeDelivery;
    },
    // End Home Delivery


    //Start Convert Profile Image To Base 64 or set placeholder

    convertImage(profilePic) {
        if (profilePic == null) {
            return require('@/assets/images/avatars/placeholderImage.png');
        } else {
            return 'data:image/*;base64,' + profilePic
        }
    },
    //End Convert Profile Image To Base 64 or set placeholder

    //Start Convert Profile Image To Base 64 or set placeholder


    // start barcode Align position
    barcodeAlignOptions() {
        var barcodeAlignOption = ['Top', 'Right', 'Bottom', 'Left', 'Center'];
        return barcodeAlignOption;
    },

    barcodeAlign() {
        var barcodeAlign = 'Center';
        return barcodeAlign;
    },
    // end barcode Align position

    // Start Screen Name Email and SMS 
    screenNameOptions: [
        { 'id': 1, 'screenName': 'Access Authentication' },
        { 'id': 2, 'screenName': 'Cancel Order' },
        { 'id': 3, 'screenName': 'New Order' },
        { 'id': 4, 'screenName': 'Ready Order' },
    ],

    // End Screen Name Email and SMS 

    // Start Dynamic Variable Options Email And SMS
    dynamicVariableOptions: [
        { 'id': 1, 'dynamicVariableName': 'Name' },
        { 'id': 2, 'dynamicVariableName': 'Date' },
        { 'id': 3, 'dynamicVariableName': 'Order No' },
        { 'id': 4, 'dynamicVariableName': 'Delivery Date' },
    ],

    // End Dynamic Variable Options Email And SMS

    //Start Barcode width and height
    widthOptions: ['40', '75', '100', '120', '150', '180', '200', '230'],
    heightOptions: ['40', '75', '100', '120', '150', '180', '200', '230'],
    //End Barcode width and height


    //start Digit Count of customer and employee id

    digits_count(n) {
        var count = 0;
        if (n >= 1) ++count;

        while (n / 10 >= 1) {
            n /= 10;
            ++count;
        }

        return count;
    },
    //End Digit Count of customer and employee id

    //start Communication Preference 

    communicationPreferenceOptions: [
        { 'id': 1, 'name': 'SMS' },
        { 'id': 2, 'name': 'EMAIL' },
        { 'id': 3, 'name': 'BOTH SMS / EMAIL' },
    ],

    //End Communication Preference 


    getAblityByRoles(userRole) {
        if (userRole == 'SUPER ADMIN') {
            return [
                {
                    action: 'manage',
                    subject: 'all',
                },
            ]
        }
        else if (userRole == 'ADMIN') {
            return [
                {
                    action: 'manage',
                    subject: 'all',
                },
            ]
        }

        else if (userRole == 'OPERATOR') {
            return [
                {
                    action: 'manage',
                    subject: 'all',
                },
            ]
        }

        else if (userRole == 'USER') {
            return [
                {
                    action: 'manage',
                    subject: 'all',
                },
            ]
        }

    },


}
export const getAuthorized = (path) => {

    const roles = JSON.parse(localStorage.getItem('roles'))
    if (roles[0].name == 'SUPER ADMIN') {
        return getPermisiion(path,
            true, //Home
            true, //Dashboard

            true, // Customer List
            true, //Add New

            true, //Wallert List
            true, //Transaction Deatils

            true, //New Order
            true, // Order details
            true, // Cancel Order

            true, // Print tag
            true, //Send To Workshop
            true, // Receive Workshop
            true, // Pending Finishing
            true, // Return To Workshop
            true, // Return To Store

            true, // Account Group
            true, //Account
            true, //Add Vendor
            true, //Vendor Transaction Detail
            true, // Detail Cash Day Book
            true, // Payment
            true, // Receipt
            true, // Account Receiable

            true, // all CustomersList
            true, // Orders Report
            true, // Sales Delivery
            true, // Payment Adjustment

            true, // Store Information
            true, // Workshop Information
            true, // Item Category
            true, //items
            true, // services
            true, // priceList
            true, // Topup services
            true, // Brands
            true, // Garment Remarks
            true, // Color
            true, // Garment Return Cause
            true, // Payment Adjustment Type
            true, // Payment Mode Type
            true, // Wallet Transaction Type
            true, // Customer Preference
            true, // Add Employee
            true, //Employee List

            true, // Setup
            true, // Holidays
            true, // Desgin Tag
            true, // Search Invoice
            true, // SMS Configuration
            true, // Daily Dashboard
            true, //Email Configuration
        )
    } else if (roles[0].name == 'ADMIN') {

        return getPermisiion(path,
            true, //Home
            true, //Dashboard

            true, // Customer List
            true, //Add New

            true, //Wallert List
            true, //Transaction Deatils

            true, //New Order
            true, // Order details
            true, // Cancel Order

            true, // Print tag
            true, //Send To Workshop
            true, // Receive Workshop
            true, // Pending Finishing
            true, // Return To Workshop
            true, // Return To Store

            true, // Account Group
            true, //Account
            true, //Add Vendor
            true, //Vendor Transaction Detail
            true, // Detail Cash Day Book
            true, // Payment
            true, // Receipt
            true, // Account Receiable

            true, // all CustomersList
            true, // Orders Report
            true, // Sales Delivery
            true, // Payment Adjustment

            true, // Store Information
            true, // Workshop Information
            true, // Item Category
            true, //items
            true, // services
            true, // priceList
            true, // Topup services
            true, // Brands
            true, // Garment Remarks
            true, // Color
            true, // Garment Return Cause
            true, // Payment Adjustment Type
            true, // Payment Mode Type
            true, // Wallet Transaction Type
            true, // Customer Preference
            true, // Add Employee
            true, //Employee List

            true, // Setup
            true, // Holidays
            true, // Desgin Tag
            true, // Search Invoice
            true, // SMS Configuration
            true, // Daily Dashboard
            true, //Email Configuration
        )

    } else if (roles[0].name == 'OPERATOR') {

        return getPermisiion(path,
            false, //Home
            false, //Dashboard

            false, // Customer List
            false, //Add New

            false, //Wallert List
            false, //Transaction Deatils

            false, //New Order
            false, // Order details
            false, // Cancel Order

            false, // Print tag
            false, //Send To Workshop
            false, // Receive Workshop
            false, // Pending Finishing
            false, // Return To Workshop
            false, // Return To Store

            false, // Account Group
            false, //Account
            false, //Add Vendor
            false, //Vendor Transaction Detail
            false, // Detail Cash Day Book
            false, // Payment
            false, // Receipt
            false, // Account Receiable

            false, // all CustomersList
            false, // Orders Report
            false, // Sales Delivery
            false, // Payment Adjustment

            false, // Store Information
            false, // Workshop Information
            false, // Item Category
            false, //items
            false, // services
            false, // priceList
            false, // Topup services
            false, // Brands
            false, // Garment Remarks
            false, // Color
            false, // Garment Return Cause
            false, // Payment Adjustment Type
            false, // Payment Mode Type
            false, // Wallet Transaction Type
            false, // Customer Preference
            false, // Add Employee
            false, //Employee List

            false, // Setup
            false, // Holidays
            false, // Desgin Tag
            false, // Search Invoice
            false, // SMS Configuration
            false, // Daily Dashboard
            false, //Email Configuration
        )

    } else if (roles[0].name == 'USER') {
        return getPermisiion(path,
            true, //Home
            true, //Dashboard

            true, // Customer List
            true, //Add New

            true, //Wallert List
            true, //Transaction Deatils

            true, //New Order
            true, // Order details
            true, // Cancel Order

            true, // Print tag
            true, //Send To Workshop
            true, // Receive Workshop
            true, // Pending Finishing
            true, // Return To Workshop
            true, // Return To Store

            true, // Account Group
            true, //Account
            true, //Add Vendor
            true, //Vendor Transaction Detail
            true, // Detail Cash Day Book
            true, // Payment
            true, // Receipt
            true, // Account Receiable

            true, // all CustomersList
            true, // Orders Report
            true, // Sales Delivery
            true, // Payment Adjustment

            true, // Store Information
            true, // Workshop Information
            true, // Item Category
            true, //items
            true, // services
            true, // priceList
            true, // Topup services
            true, // Brands
            true, // Garment Remarks
            true, // Color
            true, // Garment Return Cause
            true, // Payment Adjustment Type
            true, // Payment Mode Type
            true, // Wallet Transaction Type
            true, // Customer Preference
            true, // Add Employee
            true, //Employee List

            true, // Setup
            true, // Holidays
            true, // Desgin Tag
            true, // Search Invoice
            true, // SMS Configuration
            true, // Daily Dashboard
            true, //Email Configuration
        )

    }


}

export const getPermisiion = (path, home, apolloDashboard, customerList, addNew, walletList, transactionDetail, newOrder, orderDetail, cancelOrder,
    printTag, sendWorkshop, receiveWorkshop, pendingFinishing, returnToWorkShop, returnToStore, accountsGroup, account, addVendor, vendorTransactionDetail, detailCashDayBook, payment,
    receipt, accountReceivable, allCustomerList, orders, salesDelivery, paymentAdjustment, storeInformation, workshopInformation, itemCategory,
    items, services, priceList, topupServices, brand, garmentRemarks, colour, garmentReturnCause, paymentAdjustmentType, paymentModeType, walletTransactionType,
    customerPreference, addEmployee, employeeList, setup, holidays, designTag, searchInvoice, smsConfiguration, dailyDashboard, emailConfiguration) => {
        // alert(path);
        switch (path) {
        
        // Home
        case 'home': // Home
            return home;
            break;

        // Dashboard
        case 'apollo-dashboard': // Dashboard
            return apolloDashboard;
            break;

        // Customer
        case 'customerList': // Customer List
            return customerList;
            break;

        case 'addNew': // Add New
            return addNew;
            break;

        //Wallet
        case 'walletList': // Wallet List
            return walletList;
            break;

        case 'transactionDetail': // Transaction Deatils
            return transactionDetail;
            break;

        // Order
        case 'newOrder': // New Order
            return newOrder;
            break;

        case 'orderDetail': // Order details
            return orderDetail;
            break;

        case 'cancelOrder': // Cancel Order
            return cancelOrder;
            break;

        //Process
        case 'printTag': //print Tag
            return printTag;
            break;

        case 'sendWorkshop': // Send Workshop
            return sendWorkshop;
            break;

        case 'receiveWorkshop': // Receive Workshop
            return receiveWorkshop;
            break;

        case 'pendingFinishing': // Pending Finishing
            return pendingFinishing;
            break;

        case 'returnToWorkShop': // Return To WorkShop
            return returnToWorkShop;
            break;

        case 'returnToStore': // Return To Store
            return returnToStore;
            break;

        //Account
        case 'accountsGroup': // Account Group
            return accountsGroup;
            break;

        case 'account': // Account
            return account;
            break;

        case 'addVendor': // Add Vendor
            return addVendor;
            break;

        case 'vendorTransactionDetail':// Vendor Transaction Detail
            return vendorTransactionDetail;
            break;

        case 'detailCashDayBook':// Detail Cash Day Book
            return detailCashDayBook;
            break;

        case 'payment': // Payment
            return payment;
            break;

        case 'receipt': // Receipt
            return receipt;
            break;

        case 'accountReceivable': // Account Receiable
            return accountReceivable;
            break;

        // Reports
        case 'allCustomerList': // all CustomersList
            return allCustomerList;
            break;

        case 'orders': // Orders
            return orders;
            break;

        case 'salesDelivery': // Sales Delivery
            return salesDelivery;
            break;

        case 'paymentAdjustment': // Payment Adjustment
            return paymentAdjustment;
            break;

        // Master Data
        case 'storeInformation': // Store Information
            return storeInformation;
            break;

        case 'workshopInformation': // Workshop Information
            return workshopInformation;
            break;

        case 'itemCategory': // Item Category
            return itemCategory;
            break;

        case 'items': //items
            return items;
            break;

        case 'services': // services
            return services;
            break;

        case 'priceList': // priceList
            return priceList;
            break;

        case 'topupServices': // Topup services
            return topupServices;
            break;

        case 'brand': // Brands
            return brand;
            break;

        case 'garmentRemarks': // Garment Remarks
            return garmentRemarks;
            break;

        case 'colour': // Color
            return colour;
            break;

        case 'garmentReturnCause': // Garment Return Cause
            return garmentReturnCause;
            break;

        case 'paymentAdjustmentType': // Payment Adjustment Type
            return paymentAdjustmentType;
            break;

        case 'paymentModeType': // Payment Mode Type
            return paymentModeType;
            break;

        case 'walletTransactionType': // Wallet Transaction Type
            return walletTransactionType;
            break;

        case 'customerPreference': // Customer Preference
            return customerPreference;
            break;

        case 'addEmployee': // Add Employee
            return addEmployee;
            break;

        case 'employeeList': //Employee List
            return employeeList;
            break;

        //
        //ADMIN
        //
        case 'setup': // Setup
            return setup;
            break;

        case 'holidays': // Holidays
            return holidays;
            break;

        case 'designTag': // Desgin Tag
            return designTag;
            break;

        case 'searchInvoice': // Search Invoice
            return searchInvoice;
            break;

        case 'smsConfiguration': // SMS Configuration
            return smsConfiguration;
            break;

        case 'dailyDashboard': // Daily Dashboard
            return dailyDashboard;
            break;

        case 'emailConfiguration'://Email Configuration
            return emailConfiguration;
            break;

        default:
            return true;
            break;
    }
}